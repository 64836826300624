body {
    padding: 0;
    margin: 0
}

html {
    padding: 0;
    margin: 0
}

.bg_blue {
    background-color: #755b9b
}

.head_link {
    color: #fff;
    text-decoration: none;
    padding-left: 10px
}

.icon_image {
    filter: invert(1);
    height: 20px;
    width: 20px
}

.icon_image_star {
    filter: invert(0);
    height: 30px;
    width: 30px
}

.text {
    color: #fff
}

.content_end {
    display: flex;
    justify-content: flex-end
}

.icon {
    color: #fff
}

.nav_item {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-left: auto
}

.nav_item:hover {
    color: #755b9b
}

.logo_img {
    height: 50px;
    width: 100%
}

.set_bgc {
    background-color: #755b9b
}

.set_bgi {
    background-image: url(./Image/bg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.apt_book {
    height: 5rem
}

.bg_white {
    box-shadow: 0 0 10px rgb(0 0 0 / .5);
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-left: 8%;
    opacity: .8;
    color: #000
}

.apt_book_text {
    color: #fff;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center
}

.submit_app_btn {
    margin-left: 10px
}

.click_mob_call {
    color: #fff;
    text-decoration: none
}

.book_app_row {
    width: 90%
}

.modal-content {
    margin-top: 40%
}

.form_modal_button {
    background-color: #755b9b !important;
    color: #fff
}

.modal-footer {
    display: flex;
    justify-content: center !important
}

.carousel_imag {
    height: 80vh
}

.carousel_cont {
    padding: 0px !important
}

.block {
    display: block;
    padding-bottom: 20px
}

.input_area {
    width: 90%;
    margin-left: 10px;
    border-radius: 8px;
    border: 1px solid #755b9b;
    height: 35px;
    padding-left: 10px
}

.input_area:focus {
    outline: none
}

.input_msg {
    height: 60px
}

.label_font {
    font-size: 14px;
    line-height: 27px;
    margin-left: 10px;
    margin-top: 8px
}

.about_bold {
    font-weight: 700
}

.submit_button {
    width: 150px;
    padding: 10px 25px;
    border-radius: 30px;
    background-color: #755b9b;
    color: #fff;
    border: none;
    margin-top: 20px
}

.welcome_blue {
    color: #755b9b;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px
}

.wel_para {
    font-size: 15px;
    color: #555;
    line-height: 25px;
    word-spacing: 1.2px
}

.welcome_section {
    margin-bottom: 50px
}

.error {
    color: red;
    font-size: 12px
}

.appoint_form_img {
    height: auto;
    width: 100%;
    border-radius: 10px 0 0 10px
}

.appoint_form {
    box-shadow: 0 0 10px rgb(0 0 0 / .5);
    padding: 20px 20px 20px 10px;
    border-radius: 20px
}

.align_right {
    padding: 0
}

.foot_bgc {
    background-color: #333;
    color: #fff
}

.foot_head {
    font-weight: 400;
    margin-bottom: 20px
}

.detail {
    list-style-type: none;
    font-size: 16px;
    margin-bottom: 15px
}

.jus_cont_btw {
    justify-content: space-between
}

.details {
    margin-bottom: 5px;
    list-style: none
}

.add {
    padding-left: 0
}

.design_bgc {
    background-color: #755b9b;
    height: 60px;
    color: #fff;
    text-align: center;
    padding-top: 15px
}

.our_link {
    color: #fff;
    text-decoration: none;
    font-size: 14px
}

.our_link:hover {
    text-decoration: underline
}

.social_media {
    display: flex;
    padding-left: 10px
}

.sm_icon {
    list-style: none;
    margin-right: 20px;
    font-size: 20px
}

.footer_social_icon {
    height: 35px;
    border-radius: 8px
}

.footer_social_icon_fb {
    height: 32px;
    border-radius: 5px
}

.mob_two {
    padding-top: 15px
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / .7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center
}

.modal-content button {
    background: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px
}

.privacy_span {
    font-weight: 700
}
.copy_web{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: large;
}

@media screen and (max-width:768px) {
    .headbar_link {
        display: inline;
        text-align: center;
        margin-bottom: 5px
    }

    .our_association {
        height: 50vh
    }

    .text {
        margin-bottom: 0
    }

    .appoint_form_img {
        border-radius: 10px 10px 10px 10px !important
    }

    .carousel_imag {
        height: 40vh !important
    }
}

@media screen and (max-width:400px) {
    .carousel_imag {
        height: 30vh !important
    }
}